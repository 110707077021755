
import {
  _sitePlannerProvidersParams,
  _sitePlannerProviders,
} from "@evercam/shared/constants/sitePlannerProviders"
import { MESSAGES_TYPES } from "@evercam/360/components/constants"
import { UnitSystem } from "@evercam/shared/types"

import FrameViewer from "@/components/3dAppsDialogs/FrameViewer"
import SnapshotEditor from "@/components/SnapshotEditor"

export default {
  name: "SitePlannerViewer",
  components: { FrameViewer, SnapshotEditor },
  props: {
    viewType: {
      type: String,
      default: "list",
    },
    unitSystem: {
      type: String,
      default: UnitSystem.Metric,
    },
    projectId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    providerParams: _sitePlannerProvidersParams,
    isEditingImage: false,
    currentSnapshot: null,
    currentQueryParams: {},
  }),
  computed: {
    origin() {
      return `${this.$config.public.sitePlannerIframeUrl}`
    },
    params() {
      const baseParams = `${this.providerParams[_sitePlannerProviders.Evercam]}`
      const projectIdParam = this.projectId ? `${this.projectId}` : ""
      const queryParams = new URLSearchParams({
        ...this.$route.query,
        unit: this.unitSystem,
        page: this.viewType,
      }).toString()

      return `${baseParams}${projectIdParam}&${queryParams}`
    },
  },
  watch: {
    "$route.query": {
      handler(newQuery) {
        this.currentQueryParams = { ...newQuery }
      },
      immediate: true,
    },
  },
  methods: {
    async onMessageReceived(data) {
      switch (data?.type) {
        case MESSAGES_TYPES.editSnapshot:
          if (!this.isEditingImage) {
            this.openImageEditor(data?.url)
          }
          break
        default:
          break
      }
    },
    openImageEditor(urlData) {
      this.currentSnapshot = {
        snapshot: {
          data: urlData,
          createdAt: new Date(),
        },
      }
      this.isEditingImage = true
    },
    pushQueryParams(parameters) {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        ...parameters,
      }
      this.$router.push({
        path: this.$route.path,
        query: this.currentQueryParams,
      })
    },
  },
}
